import React, { useState, useEffect } from "react";
import Forum from "./Forum";
import "./Forums.css";

const Forums = () => {
  const [forums, setForums] = useState([]);

  const fetchData = async () => {
    const data = await window.TALKVIOAPI("forums", { tree: true });
    setForums(data.forums);
    document.title = `${__("Sections")} - Talkvio`;
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="forums">
        {forums.map((forum) => (
          <Forum forum={forum} key={forum.forumid} />
        ))}
      </div>
    </div>
  );
};
export default Forums;

import React from "react";
import "./Tooltip.css";

export default (props) => {
  let className = "tooltip";
  if (props.right) className += " right";
  if (props.top) className += " top";
  const style = {};
  if (props.maxWidth) {
    style.maxWidth = props.maxWidth + "px";
  }
  if (props.width) {
    style.width = props.width + "px";
  }
  if (props.offsetX) {
    style.left = props.offsetX + "px";
  }
  if (props.offsetY) {
    style.top = props.offsetY + "px";
  }
  return (
    <div className={className}>
      {props.children}
      <span className="tooltiptext" style={style}>
        {props.hint}
      </span>
    </div>
  );
};

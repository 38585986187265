import React, { useEffect } from "react";
import RecentPosts from "./RecentPosts";

const Liked = () => {
  useEffect(() => {
    document.title = `${__("Liked posts")} - Talkvio`;
  }, []);

  return (
    <div>
      <div className="recentPosts">
        <RecentPosts
          source="liked"
          onlyLiked={true}
          recentPopularSwitcher={false}
          noLimitation={true}
          leftRatingDisplay={true}
          showReply={true}
        />
      </div>
    </div>
  );
};
export default Liked;

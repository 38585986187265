import React from "react";
import "./ConfirmMessage.css";

window.confirmMessage = (title, onYes = () => {}, onNo = () => {}) => {
  document.getElementsByClassName("confirm-container")[0].style.display =
    "block";
  document.getElementsByClassName("confirm-title")[0].innerHTML = title;
  document.getElementsByClassName("confirm-yes")[0].onclick = () => {
    document.getElementsByClassName("confirm-container")[0].style.display =
      "none";
    onYes();
  };
  document.getElementsByClassName("confirm-no")[0].onclick = () => {
    document.getElementsByClassName("confirm-container")[0].style.display =
      "none";
    onNo();
  };
};

const ConfirmMessage = () => {
  return (
    <div className="confirm-container typicalBlock">
      <div className="confirm-message">
        <h2 className="confirm-title"></h2>
        <button className="confirm-yes clickButton">{__("Confirm")}</button>
        <button className="confirm-no clickButton">{__("Cancel")}</button>
      </div>
    </div>
  );
};
export default ConfirmMessage;

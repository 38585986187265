import React, { useEffect, useState } from "react";
import QRCode from "qrcode";
import "./QRCode.css";

const QRCodeComponent = ({
  value,
  size = 256,
  level = "M",
  margin = 4,
  color = {
    dark: "#000000",
    light: "#ffffff",
  },
  className = "",
  showValue = false,
}) => {
  const [qrDataUrl, setQrDataUrl] = useState("");

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const dataUrl = await QRCode.toDataURL(value, {
          width: size,
          margin: margin,
          color: color,
          errorCorrectionLevel: level,
        });
        logT(
          "QRCode",
          "QR Code generated:",
          value,
          "data size",
          dataUrl.length,
        );
        setQrDataUrl(dataUrl);
      } catch (err) {
        logTE("QRCode", "Error generating QR code:", err);
      }
    };

    if (value) {
      generateQRCode();
    }
  }, [value, size, level, margin, color]);
  const handleDownload = () => {
    if (!qrDataUrl) return;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = qrDataUrl;

    // Extract thread ID and title from URL if possible
    let filename = "qrcode-talkvio";

    // Try to match the pattern like "threads/35303-s-dobrim-utrom"
    const threadMatch = value.match(/threads\/(\d+)[-]([^?&#]+)/);

    if (threadMatch) {
      // If URL matches the expected pattern, use thread ID and title
      const threadId = threadMatch[1];
      const threadTitle = threadMatch[2];
      filename = `qrcode-talkvio-${threadId}-${threadTitle}`;
    } else {
      // Fallback: just use a substring of the value
      filename = `qrcode-talkvio-${value.substring(0, 20).replace(/[^a-zA-Z0-9]/g, "-")}`;
    }

    link.download = `${filename}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    logT("QRCode", "QR Code downloaded", value, filename);
  };

  return (
    <div className={`qr-code-container ${className}`}>
      <img
        src={qrDataUrl}
        alt="QR Code"
        className="qr-code-image"
        style={{ width: size, height: size }}
      />
      {showValue && (
        <div className="qr-code-value" style={{ maxWidth: size }}>
          {value}
        </div>
      )}
      <button
        className="qr-code-download-button"
        onClick={handleDownload}
        disabled={!qrDataUrl}
      >
        {__("Download QR Code")}
      </button>
    </div>
  );
};

export default QRCodeComponent;

import React from "react";
import ClickOutsideContainer from "./ClickOutsideContainer";
import RelativeContainer from "./RelativeContainer";
import "./Modal.css";

const Modal = ({
  children,
  isOpen,
  onClose,
  className = "",
  overlayClassName = "",
  contentClassName = "",
}) => {
  if (!isOpen) return null;

  return (
    <div className={`modal-window ${overlayClassName}`}>
      <ClickOutsideContainer isShow={isOpen} setIsShow={() => onClose()}>
        <RelativeContainer>
          <div className={`modal ${className}`}>
            <div className={`modal-content ${contentClassName}`}>
              {children}
            </div>
          </div>
        </RelativeContainer>
      </ClickOutsideContainer>
    </div>
  );
};

export default Modal;

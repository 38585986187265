import React, { useEffect, lazy, Suspense } from "react";
import { setCookie } from "../global/Global";
import { setStorageValue } from "../global/Storage";
import "./SpecialPage.css";

// Lazy load special page content components
const AdsContentPage = lazy(() => import("./special-pages/AdsContentPage"));
const ContestPage = lazy(() => import("./special-pages/ContestPage"));
const PolicyPage = lazy(() => import("./special-pages/PolicyPage"));
const RefContestPage = lazy(() => import("./special-pages/RefContestPage"));
const RefWorkPage = lazy(() => import("./special-pages/RefWorkPage"));

// Loading component to show while lazy components are loading
const LoadingPage = () => (
  <div className="specialPage">
    <div style={{ textAlign: "center", padding: "2rem" }}>
      Loading content...
    </div>
  </div>
);

const SpecialPage = ({ page }) => {
  useEffect(() => {
    if (page == "contest") {
      document.title = `Talkvio - ${__("Contest") + " #3"}`;
    }
    if (page == "adscontent") {
      document.title = `Talkvio - Content creator`;
    }
    if (page == "refcontest") {
      document.title = `Talkvio - ${__("Referral Contest")}`;
    }
    if (page == "refwork") {
      document.title = `Talkvio - ${__("Referral Program")}`;
    }

    if (page == "contest") {
      setStorageValue("talkvio_source", "writecontest", {
        storage: "localstorage",
      });
    } else if (page == "refcontest") {
      setStorageValue("talkvio_source", "refcontest", {
        storage: "localstorage",
      });
    } else if (page == "refwork") {
      setStorageValue("talkvio_source", "refwork", {
        storage: "localstorage",
      });
    }
  }, []);

  useEffect(() => {
    window.setLeftPanelTopOffet(80);
    window.setLeftPanelDisplay(true);

    return () => {
      window.resetLeftPanelTopOffet();
      window.setLeftPanelDisplay(false);
    };
  }, []);

  // Render the appropriate page based on the page prop
  const renderPageContent = () => {
    return (
      <Suspense fallback={<LoadingPage />}>
        {page === "adscontent" && <AdsContentPage />}
        {page === "contest" && <ContestPage />}
        {page === "policy" && <PolicyPage />}
        {page === "refcontest" && <RefContestPage />}
        {page === "refwork" && <RefWorkPage />}
      </Suspense>
    );
  };

  return (
    <div className="normalBackground">
      <div className="specialPage">{renderPageContent()}</div>
    </div>
  );
};

export default SpecialPage;

import React, { useContext } from "react";
import { eraseCookie, getCookie } from "../global/Global";
import { LoginContext } from "./Login";
import "./RemoveAccount.css";

export default ({}) => {
  const { user } = useContext(LoginContext);

  return (
    <div className="typical-page typicalBlock">
      {user?.userid > 0 ? (
        <div className="removeAccount">
          <div className="removeAccountText">
            {__(
              "Warning! Do you want remove your account and all data belongs to it? This operation is irreversible. You will lost all your account data.",
            )}
          </div>
          <div>
            <button
              className="clickButton removeAccountButton"
              onClick={() => {
                window.confirmMessage(
                  __("Are you sure that you wanna delete your account?"),
                  async () => {
                    const token = getCookie("token");
                    const userid = parseInt(getCookie("userid"));
                    const data = await window.TALKVIOAPI("removeAccount", {
                      userid,
                      token,
                    });
                    window.displayError(data);
                    if (data.success) {
                      eraseCookie("userid");
                      eraseCookie("token");
                      eraseCookie("darkmode");
                      if (
                        typeof window.TalkvioAndroid != "undefined" &&
                        window.TalkvioAndroid.logOutPerformed
                      ) {
                        window.TalkvioAndroid.logOutPerformed();
                      }
                      window.location.href = window.location.href;
                    }
                  },
                );
              }}
            >
              {__("I want to delete my account anyway")}
            </button>
          </div>
        </div>
      ) : (
        <div className="removeAccount">
          <div className="removeAccountText">
            {__(
              "You must be logined to remove account. Please login to Talkvio.",
            )}
          </div>
        </div>
      )}
    </div>
  );
};

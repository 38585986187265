import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { dataURItoBlob } from "../global/FileData";
import {
  cutHtmlTextContent,
  cutLexicalString,
  getCookie,
  htmlEscape,
  isMobile,
  links2html,
  mail2html,
  transliterateLink,
} from "../global/Global";
import ImageZoom from "./ImageZoom";
import useDidUpdateEffect from "../global/DidUpdateEffect";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./Login";
import "./Article.css";
import { setStorageValue } from "../global/Storage";
import { LazyCall } from "../global/LazyCall";
const DOMPurify = require("dompurify")(window);

const parsePost = (text) => {
  if (!text) return;
  // FIXME: too much performace usage for mail
  // text = mail2html(text);
  text = links2html(text);

  // Hashtags will be parsed safely after sanitization in the TextBlock component

  return text;
};

const articleObject = (potencialObj) => {
  if (potencialObj.length < 3) return;

  if (potencialObj[0] != "{" && potencialObj[potencialObj.length - 1] != "}")
    return;

  let obj;
  try {
    obj = JSON.parse(potencialObj);
  } catch (e) {
    return;
  }

  if (!obj.blocks) return;

  return obj;
};

const VideoBlock = ({
  block,
  userid,
  visible,
  onPiP = null,
  blockPiPMode = false,
}) => {
  const videoRef = useRef();
  const gifSickerRef = useRef();
  const videoIdRef = useRef(
    (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7),
  );
  const { user } = useContext(LoginContext);
  const [pipMode, setPipMode] = useState(false);

  const enterPipMode = () => {
    if (pipMode) {
      return;
    }
    if (videoRef.current) {
      const videoHeight =
        videoRef.current.clientHeight || videoRef.current.offsetHeight;
      if (videoRef.current?.parentElement?.parentElement) {
        logT(
          "video",
          "height before pip-mode",
          videoHeight,
          "update block height",
        );
        videoRef.current.parentElement.parentElement.style.height = `${videoHeight}px`;
      }
    }
    setPipMode(true);
  };

  useDidUpdateEffect(() => {
    if (pipMode) {
      logT("video", "enter PiP mode");
      if (onPiP) {
        onPiP(true);
      }
    } else {
      logT("video", "leave PiP mode");
      if (onPiP) {
        onPiP(false);
      }
      // Reset height when leaving PiP mode
      if (videoRef?.current?.parentElement?.parentElement) {
        logT("video", "reset block height");
        videoRef.current.parentElement.parentElement.style.height = "";
      }
    }
  }, [pipMode]);

  useDidUpdateEffect(() => {
    if (!pipMode) {
      return;
    }

    if (!blockPiPMode) {
      logT("video", "block is focused, leave PiP mode");
      setPipMode(false);
    }
  }, [blockPiPMode]);

  useEffect(() => {
    if (!window.IntersectionObserver) {
      return;
    }

    if (!videoRef?.current) {
      return;
    }

    if (block.type == "animation") {
      return;
    }

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            logT("video", "video in view", videoIdRef.current);
            if (
              user?.settings?.videoOnScreenStart &&
              videoRef.current?.paused
            ) {
              if (block.spoiler && !visible) {
                logT("video", "video is spoiler, ignore");
                return;
              }
              if (block.adult && !visible) {
                logT("video", "video is adult, ignore");
                return;
              }

              videoRef.current.play().catch((e) => {
                if (e.name === "NotAllowedError") {
                  logT("video", "hasnt activated yet, ignore");
                }
              });
              logT("video", "video autostart because of setting");
            }
            if (!window.talkvioFocusedPlayers)
              window.talkvioFocusedPlayers = [];
            window.talkvioFocusedPlayers.push(videoRef.current);
            logT(
              "video",
              "add focus player",
              "index",
              window.talkvioFocusedPlayers.length - 1,
              "players",
              window.talkvioFocusedPlayers.length,
            );

            // Exit PiP mode when video is back in view
            if (pipMode) {
              setPipMode(false);
            }
          } else {
            logT("video", "video out of view", videoIdRef.current);
            if (window.talkvioFocusedPlayers) {
              let index = window.talkvioFocusedPlayers.indexOf(
                videoRef.current,
              );
              if (index >= 0) {
                window.talkvioFocusedPlayers.splice(index, 1);
                logT(
                  "video",
                  "remove focus player",
                  "index",
                  index,
                  "players",
                  window.talkvioFocusedPlayers.length,
                );
              }
            }
            if (
              (user?.settings?.videoOutScreenStop ||
                (typeof user?.settings?.videoOutScreenStop == "undefined" &&
                  isMobile())) &&
              (typeof user?.settings?.pipOutScreen == "undefined" ||
                !user?.settings?.pipOutScreen)
            ) {
              if (videoRef.current && !videoRef.current.paused) {
                logT("video", "video stop because of setting");
                videoRef.current.pause();
              }
              if (
                user?.settings?.videoOnScreenStart &&
                window.talkvioFocusedPlayers?.length > 0 &&
                window.talkvioFocusedPlayers[0].paused
              ) {
                logT("video", "play next focused player");
                window.talkvioFocusedPlayers[0].play().catch((e) => {
                  if (e.name === "NotAllowedError") {
                    logT("video", "hasnt activated yet, ignore");
                  }
                });
              }
            } else {
              // Enter PiP mode when video is out of view and still playing
              if (videoRef.current && !videoRef.current.paused) {
                enterPipMode();
              }
            }
          }
        });
      },
      { threshold: [0] },
    );

    observer.observe(videoRef?.current);
    logT("video", "listen visibility of video");

    const videoElement = videoRef.current;

    return () => {
      observer.disconnect();
      logT("video", "stop listen visibility of video");
      if (window.talkvioFocusedPlayers) {
        let index = window.talkvioFocusedPlayers.indexOf(videoElement);
        if (index >= 0) {
          window.talkvioFocusedPlayers.splice(index, 1);
          logT(
            "video",
            "remove old focused player",
            "index",
            index,
            "players",
            window.talkvioFocusedPlayers.length,
          );
        }
      }
    };
  }, [block.processing, block.type, visible, user]);

  const closePiP = (e) => {
    e.stopPropagation();
    setPipMode(false);
    if (videoRef.current && !videoRef.current.paused) {
      videoRef.current.pause();
    }
  };

  if (block.processing) {
    return (
      <div className="video-preview-processing">
        <img
          src={
            (process.env.NODE_ENV == "production"
              ? ""
              : "http://localhost:9989") +
            "/file/thumbnail/" +
            userid +
            "/" +
            block.file
          }
          alt={"video processing"}
        />
        <div className="processing-text">
          {__("Video in proccessing state, please wait until it finished")}
        </div>
      </div>
    );
  }

  return (
    <div className={"video-player" + (pipMode ? " pip-mode" : "")}>
      {block.type == "animation" ? (
        <div className="gif-sticker" ref={gifSickerRef}>
          GIF
        </div>
      ) : null}
      {pipMode && (
        <div className="pip-close" onClick={closePiP}>
          ✕
        </div>
      )}
      <video
        data-testid="video-block"
        className={block.type == "animation" ? "animation" : ""}
        controls={block.type != "animation"}
        loop={block.type == "animation"}
        ref={videoRef}
        poster={
          (process.env.NODE_ENV == "production"
            ? ""
            : "http://localhost:9989") +
          "/file/thumbnail/" +
          userid +
          "/" +
          block.file
        }
        onClick={(e) => {
          if (block.type == "animation") {
            if (videoRef.current.paused) {
              logT("animation", "play on click");
              videoRef.current.play();
            } else {
              logT("animation", "pause on click");
              videoRef.current.pause();
            }
          }
        }}
        onPlay={() => {
          logT("video", "playback started");
          if (window.previusBlockPlayStop) {
            logT("video", "stop previous playback");
            window.previusBlockPlayStop();
          }
          window.previusBlockPlayId = videoIdRef?.current;
          window.previusBlockPlayStop = () => {
            videoRef?.current?.pause();
            // prevent PiP mode when video is stopped by different player
            setPipMode(false);
            delete window.previusBlockPlayId;
          };
          if (gifSickerRef?.current)
            gifSickerRef.current.classList.add("playing");
          if (
            typeof window.videoPlayerVolume != "undefined" &&
            videoRef.current.volume != window.videoPlayerVolume
          ) {
            logT(
              "volume",
              "initial volume",
              window.videoPlayerVolume.toFixed(2),
            );
            videoRef.current.volume = window.videoPlayerVolume;
          }
          if (
            typeof window.videoPlayerVolumeMuted != "undefined" &&
            videoRef.current.muted != window.videoPlayerVolumeMuted
          ) {
            logT("volume", "initial muted", window.videoPlayerVolumeMuted);
            videoRef.current.muted = window.videoPlayerVolumeMuted;
          }
        }}
        onPause={() => {
          logT("video", "playback stoped");
          if (
            window.previusBlockPlayStop &&
            window.previusBlockPlayId === videoIdRef?.current
          ) {
            logT("video", "stoped current player, cleanup id ref");
            delete window.previusBlockPlayStop;
            delete window.previusBlockPlayId;
          }
          if (gifSickerRef?.current)
            gifSickerRef.current.classList.remove("playing");

          // Removed PiP mode exit on pause - we'll only exit on video end
        }}
        onEnded={() => {
          logT("video", "playback ended");

          // Exit PiP mode when video has finished playing
          if (pipMode) {
            setPipMode(false);
          }
        }}
        onVolumeChange={() => {
          logT(
            "volume",
            "change volume",
            videoRef.current.volume.toFixed(2),
            "muted",
            videoRef.current.muted,
          );
          if (
            window.videoPlayerVolume != videoRef.current.volume ||
            window.videoPlayerVolumeMuted != videoRef.current.muted
          ) {
            LazyCall(
              "videoVolumeUpdater",
              async () => {
                const token = getCookie("token");
                const userid = parseInt(getCookie("userid"));
                if (!userid || !token) {
                  setStorageValue("video_volume", videoRef.current.volume, {
                    storage: "localstorage",
                  });
                  setStorageValue("video_muted", videoRef.current.muted, {
                    storage: "localstorage",
                  });
                  return;
                }
                const data = await window.TALKVIOAPI("setSettings", {
                  userid,
                  token,
                  settings: {
                    videoVolume: videoRef.current.volume,
                    videoMute: videoRef.current.muted,
                  },
                  silent: true,
                });
              },
              3000,
            );
          }
          window.videoPlayerVolume = videoRef.current.volume;
          window.videoPlayerVolumeMuted = videoRef.current.muted;
        }}
      >
        <source
          src={
            (process.env.NODE_ENV == "production"
              ? ""
              : "http://localhost:9989") +
            "/file/" +
            userid +
            "/" +
            block.file
          }
        />
      </video>
    </div>
  );
};

const YoutubeBlock = ({
  block,
  description,
  onPiP = null,
  blockPiPMode = false,
}) => {
  const [playState, setPlayState] = useState(false);
  const [pipMode, setPipMode] = useState(false);
  const fallbackRef = useRef(false);
  const observerRef = useRef();
  const iframeRef = useRef();
  const videoIdRef = useRef(
    (Math.random() + 1).toString(36).substring(7) +
      (Math.random() + 1).toString(36).substring(7),
  );
  const { user } = useContext(LoginContext);

  const enterPipMode = (element) => {
    if (element) {
      const elementHeight = element.clientHeight || element.offsetHeight;
      logT("youtube", "height before pip-mode", elementHeight);
      if (element.parentElement && element.parentElement.parentElement) {
        element.parentElement.parentElement.style.height = `${elementHeight}px`;
      }
    }
    setPipMode(true);
  };

  useDidUpdateEffect(() => {
    if (pipMode) {
      logT("youtube", "enter PiP mode");
      if (onPiP) {
        onPiP(true);
      }
    } else {
      logT("youtube", "leave PiP mode");
      if (onPiP) {
        onPiP(false);
      }
      // Reset height when leaving PiP mode
      const element = iframeRef.current;
      if (element?.parentElement?.parentElement) {
        element.parentElement.parentElement.style.height = "";
      }
    }
  }, [pipMode]);

  useDidUpdateEffect(() => {
    if (!pipMode) {
      return;
    }

    if (!blockPiPMode) {
      logT("youtube", "block is focused, leave PiP mode");
      setPipMode(false);
    }
  }, [blockPiPMode]);

  useEffect(() => {
    if (playState) {
      if (window.previusBlockPlayStop) {
        logT("youtube", "stop previous playback");
        window.previusBlockPlayStop();
      }
      window.previusBlockPlayId = videoIdRef?.current;
      window.previusBlockPlayStop = () => {
        setPlayState(false);
        setPipMode(false);
      };
    } else {
      if (window.previusBlockPlayId === videoIdRef?.current) {
        logT("youtube", "cleanup id ref");
        delete window.previusBlockPlayId;
        delete window.previusBlockPlayStop;
      }
    }
  }, [playState]);

  const hadleVideoElement = (element) => {
    if (element) {
      iframeRef.current = element;
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
      observerRef.current = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              if (
                (user?.settings?.videoOutScreenStop ||
                  (typeof user?.settings?.videoOutScreenStop == "undefined" &&
                    isMobile())) &&
                (typeof user?.settings?.pipOutScreen == "undefined" ||
                  !user?.settings?.pipOutScreen)
              ) {
                logT("youtube", "video out of view, pause");
                setPlayState(false);
              } else if (playState) {
                // Enter PiP mode when video is out of view and still playing
                enterPipMode(element);
              }
            }
          });
        },
        { threshold: [0] },
      );
      observerRef.current.observe(element);
      logT("youtube", "listen visibility of youtube video");
    } else {
      iframeRef.current = null;
      observerRef.current.disconnect();
      observerRef.current = null;
      logT("youtube", "stop listen visibility of youtube video");
    }
  };

  const closePiP = (e) => {
    e.stopPropagation();
    setPipMode(false);
    setPlayState(false);
  };

  return (
    <div className={"youtube-player" + (pipMode ? " pip-mode" : "")}>
      {pipMode && (
        <div className="pip-close" onClick={closePiP}>
          ✕
        </div>
      )}
      {playState ? (
        <iframe
          ref={hadleVideoElement}
          frameBorder="0"
          allowFullScreen="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          width="100%"
          height="100%"
          src={"https://www.youtube.com/embed/" + block.data + "?autoplay=1"}
        />
      ) : (
        <div>
          <img
            onClick={() => setPlayState(true)}
            src={`https://i.ytimg.com/vi/${block.data}/maxresdefault.jpg`}
            alt={
              description ? `youtube video for ${description}` : "youtube video"
            }
            onLoad={(e) => {
              if (
                e.target?.naturalWidth == 120 &&
                e.target?.naturalHeight == 90
              ) {
                if (fallbackRef?.current) {
                  return;
                }
                fallbackRef.current = true;
                logT("youtube", "no maxres for", block.data, "fallback to hq");
                e.target.src = `https://i.ytimg.com/vi/${block.data}/hqdefault.jpg`;
              }
            }}
            onError={(e) => {
              if (!e.target) {
                return;
              }
              if (fallbackRef?.current) {
                return;
              }
              fallbackRef.current = true;
              logT(
                "youtube",
                "no maxres for",
                block.data,
                "fallback to hq with error",
              );
              e.target.src = `https://i.ytimg.com/vi/${block.data}/hqdefault.jpg`;
            }}
          />
          <div
            onClick={() => setPlayState(true)}
            className="youtube-play-background-button"
          ></div>
          <svg
            onClick={() => setPlayState(true)}
            className="youtube-play-button"
            viewBox="0 0 16 16"
          >
            <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
          </svg>
        </div>
      )}
    </div>
  );
};

const TextBlock = ({
  block,
  index,
  maxTextSize,
  convertToText,
  isTextShowOnClick,
  maxTextShowOnClick,
  sharedObject,
  onRenderFinish,
}) => {
  const blockTextRef = useRef();
  const navigate = useNavigate();

  // parse text
  useEffect(() => {
    if (!blockTextRef?.current) return;

    const users = blockTextRef.current.getElementsByClassName("ask-user");
    if (users?.length > 0) {
      for (let i = 0; i < users.length; i++) {
        let userid =
          users[i].dataset.userid ||
          users[i].getElementsByTagName("input")?.[0]?.value;
        if (userid) {
          userid = parseInt(userid);
          users[i].onclick = () => {
            logT("TextBlock", "navigate by click on nick to", "user", userid);
            navigate(`/user/${userid}`);
          };
        }
      }
    }

    const threadLinks =
      blockTextRef.current.getElementsByClassName("thread-link");
    if (threadLinks?.length > 0) {
      for (let i = 0; i < threadLinks.length; i++) {
        let postid = threadLinks[i].dataset.postid;
        let threadid = threadLinks[i].dataset.threadid;
        let title = threadLinks[i].dataset.title;
        let parentid = threadLinks[i].dataset.parentid;

        if (postid) {
          threadLinks[i].onclick = (e) => {
            e.stopPropagation();
            e.preventDefault();
            logT(
              "TextBlock",
              "navigate by click on thread link to",
              "post",
              postid,
            );
            navigate(
              parentid == 0
                ? `/threads/${threadid}-${transliterateLink(title)}`
                : `/post/${postid}`,
            );
          };
        }
      }
    }

    // Parse hashtags in text nodes only (safely)
    const parseHashtagsInNode = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent;
        if (text.includes("#")) {
          const fragment = document.createDocumentFragment();
          const hashtagRegex = /#([a-zA-Zа-яА-Я0-9_]+)/g;
          let lastIndex = 0;
          let match;

          while ((match = hashtagRegex.exec(text)) !== null) {
            // Add text before the hashtag
            if (match.index > lastIndex) {
              fragment.appendChild(
                document.createTextNode(text.substring(lastIndex, match.index)),
              );
            }

            // Create the hashtag link
            const hashtag = match[1];
            const formattedTag = hashtag
              .replaceAll("_", " ")
              .replaceAll("  ", "_");
            const link = document.createElement("a");
            link.href = `/tag/${formattedTag}`;
            link.textContent = `#${formattedTag}`;
            link.onclick = (e) => {
              e.stopPropagation();
              e.preventDefault();
              logT(
                "tag",
                "navigate by click on hashtag to",
                "tag",
                formattedTag,
              );
              navigate(`/tag/${formattedTag}`);
            };
            fragment.appendChild(link);

            lastIndex = match.index + match[0].length;
          }

          // Add any remaining text
          if (lastIndex < text.length) {
            fragment.appendChild(
              document.createTextNode(text.substring(lastIndex)),
            );
          }

          // Replace the original text node with our fragment
          node.parentNode.replaceChild(fragment, node);
          return true;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        // Skip processing inside pre, code, a tags, and elements with data-no-hashtags attribute
        if (
          ["PRE", "CODE", "A"].includes(node.nodeName) ||
          node.getAttribute("data-no-hashtags") === "true"
        ) {
          return false;
        }

        // Process child nodes
        // Create a static copy of childNodes to avoid live collection issues during modification
        const childNodes = Array.from(node.childNodes);
        childNodes.forEach((child) => parseHashtagsInNode(child));
      }
      return false;
    };

    // Parse hashtags in the entire block
    parseHashtagsInNode(blockTextRef.current);

    if (maxTextSize > 0) {
      // remove quotes
      const quotes =
        blockTextRef.current.getElementsByClassName("message-quote");
      if (quotes?.length > 0) {
        for (const quote of quotes) {
          quote.remove();
        }
      }

      const originalHtml = blockTextRef.current.innerHTML;
      if (!convertToText && !isTextShowOnClick) {
        cutHtmlTextContent(blockTextRef.current, maxTextSize);
      }

      let text = blockTextRef.current.innerText;
      const originalText = text;
      if (text.length > 0) {
        if (convertToText && !isTextShowOnClick) {
          text = cutLexicalString(text, maxTextSize, "...");
        }
        if (sharedObject) {
          if (!sharedObject.textSizes) {
            sharedObject.textSizes = [];
          }
          sharedObject.textSizes[index] = text.length;
          if (maxTextShowOnClick) {
            if (!sharedObject.originalText) {
              sharedObject.originalText = [];
            }
            if (!sharedObject.originalText[index])
              sharedObject.originalText[index] = convertToText
                ? originalText
                : originalHtml;
          }
          let needToRender = true;
          let textSizeSummary = 0;
          for (let i = 0; i < index + 1; i++) {
            textSizeSummary += sharedObject.textSizes[i] || 0;
            if (textSizeSummary >= maxTextSize) break;
          }
          if (index > 0 && textSizeSummary >= maxTextSize) {
            needToRender = false;
          }
          if (!needToRender) {
            logT(
              "article",
              "ignore render block",
              index,
              "because of limit symbols",
              textSizeSummary,
              sharedObject.textSizes,
            );
            blockTextRef.current.style.display = "none";
          } else {
            blockTextRef.current.style.display = "";
          }
        }
      }
      if (convertToText) {
        blockTextRef.current.innerHTML = text;
      }
      if (onRenderFinish && typeof window.articleRender != "undefined") {
        if (--window.articleRender == 0) {
          logT("article", "finish render all articles");
          onRenderFinish();
        }
      }
    }
  }, [block.data]);

  useLayoutEffect(() => {
    if (maxTextSize > 0 && onRenderFinish) {
      if (!window.articleRender) window.articleRender = 0;
      window.articleRender++;
    }
  }, [block.data]);

  useDidUpdateEffect(() => {
    if (!maxTextShowOnClick) return;

    if (!isTextShowOnClick) return;

    if (!sharedObject?.originalText?.[index]) return;

    // unclapse text
    blockTextRef.current.innerHTML = sharedObject.originalText[index];
    blockTextRef.current.style.display = "";
  }, [isTextShowOnClick]);

  return (
    <div
      ref={blockTextRef}
      tabIndex="-1"
      className="article-text"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(parsePost(block.data)) || "",
      }}
    />
  );
};

const Block = ({
  block,
  index,
  userid,
  maxTextSize,
  convertToText,
  isTextShowOnClick,
  maxTextShowOnClick,
  sharedObject,
  description,
  dateline,
  onRenderFinish,
  onNextPicture,
  onPrevPicture,
  zoomIndex,
  onCloseZoom,
}) => {
  const [visible, setVisible] = useState(false);
  const [pipMode, setPiPMode] = useState(false);
  const blockRef = useRef();

  if (block.type == "image" && block.data && !block.imageUrl) {
    block.imageUrl = window.URL.createObjectURL(dataURItoBlob(block.data));
  }

  useEffect(() => {
    if (!pipMode) {
      return;
    }

    if (!window.IntersectionObserver) {
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          logT("block", "visible once again, leave PiP mode");
          setPiPMode(false);
        } else {
          // nothing
        }
      });
    });
    observer.observe(blockRef.current);
    logT("block", "observe block because of pip mode");
    return () => {
      observer.disconnect();
      logT("block", "unobserve block because of pip mode");
    };
  }, [pipMode]);

  return (
    <div
      ref={blockRef}
      className={
        `block ${block.type}` +
        (block.spoiler ? " spoiler" : "") +
        (block.adult ? " adult" : "") +
        (visible ? " visible" : "") +
        (pipMode ? " pip-mode" : "")
      }
    >
      <div className="blockcontent">
        {block.type == "text" && (
          <TextBlock
            block={block}
            index={index}
            maxTextSize={maxTextSize}
            isTextShowOnClick={isTextShowOnClick}
            maxTextShowOnClick={maxTextShowOnClick}
            convertToText={convertToText}
            sharedObject={sharedObject}
            onRenderFinish={onRenderFinish}
          />
        )}
        {block.type == "image" && block.file && userid && (
          <ImageZoom
            description={block.description}
            descriptionAlt={
              block.description ||
              (description ? `${description} #${index + 1}` : null)
            }
            width={block.width}
            height={block.height}
            onPrevPicture={onPrevPicture}
            onNextPicture={onNextPicture}
            zoomed={zoomIndex == index}
            onCloseZoom={onCloseZoom}
            src={
              (process.env.NODE_ENV == "production"
                ? ""
                : "https://talkvio.com") +
              "/file/" +
              userid +
              "/" +
              block.file
            }
          />
        )}
        {block.type == "image" && block.imageUrl && !block.file && (
          <ImageZoom description={block.description} src={block.imageUrl} />
        )}
        {(block.type == "video" || block.type == "animation") &&
          block.file &&
          userid && (
            <div className="video-container">
              <VideoBlock
                block={block}
                userid={userid}
                visible={visible}
                onPiP={(pipMode) => setPiPMode(pipMode)}
                blockPiPMode={pipMode}
              />
              {dateline ? (
                <meta content={new Date(dateline * 1000).toISOString()} />
              ) : null}
              <meta
                content={
                  description
                    ? `${description} video #${index + 1}`
                    : `video block #${index + 1}`
                }
              />
            </div>
          )}
        {block.type == "youtube" && block.data && (
          <div className="youtube-container">
            <YoutubeBlock
              block={block}
              description={description}
              onPiP={(pipMode) => setPiPMode(pipMode)}
              blockPiPMode={pipMode}
            />
          </div>
        )}
        {block.type == "twitch" && block.data && (
          <div className="twitch-container">
            <iframe
              src={`https://player.twitch.tv/?${block.data?.video ? "video=" + block.data?.video : ""}${block.data?.channel ? "channel=" + block.data?.channel : ""}&parent=talkvio.com`}
              width="100%"
              height="100%"
              allowFullScreen="1"
            />
          </div>
        )}
        {block.type == "coub" && block.data && (
          <div className="coub-container">
            <iframe
              src={`https://coub.com/embed/${block.data}`}
              width="100%"
              height="100%"
              allowFullScreen="1"
              allow="encrypted-media;"
            />
          </div>
        )}
        {block.type == "rutube" && block.data && (
          <div className="rutube-container">
            <iframe
              src={`https://rutube.ru/play/embed/${block.data}`}
              width="100%"
              height="100%"
              allowFullScreen="1"
            />
          </div>
        )}
        {block.type == "vk" &&
          block.data &&
          block.data.oid &&
          block.data.id && (
            <div className="vk-container">
              <iframe
                width="853"
                height="480"
                src={`//vk.com/video_ext.php?oid=${block.data.oid}&id=${block.data.id}&hash=118cd8b378ffa95e&hd=1`}
                frameBorder="0"
                allowFullScreen="1"
              />
            </div>
          )}
      </div>
      {!visible && block.adult && !block.spoiler ? (
        <div className="blockAdultButton" onClick={() => setVisible(true)}>
          {__("Show 18+ content")}
        </div>
      ) : null}
      {!visible && block.spoiler && !block.adult ? (
        <div className="blockSpoilerButton" onClick={() => setVisible(true)}>
          {__("Show spoiler")}
        </div>
      ) : null}
      {!visible && block.spoiler && block.adult ? (
        <div
          className="blockAdultSpoilerButton"
          onClick={() => setVisible(true)}
        >
          {__("Show 18+ spoiler")}
        </div>
      ) : null}
    </div>
  );
};

const Article = ({
  data,
  className,
  collapsable = false,
  onUncollapse = null,
  onCollapsed = null,
  uncollapseDefault = false,
  textFullQuote = false,
  onTextFullQuote = null,
  noFirstImageAdult = false,
  onFirstImage = null,
  onFirstTextBlock = null,
  onFirstYoutubeBlock = null,
  onFirstVideoPreviewImage = null,
  filterType = null,
  maxTextSize = null,
  maxTextShowOnClick = false,
  convertToText = false,
  quote = false,
  description = null,
  dateline = null,
  onRenderFinish = null,
  preview = false,
  postid = null,
}) => {
  const articleBody = useRef();
  let sharedObject = useRef({});
  const [collapsed, setCollapsed] = useState(false);
  const [uncollapse, setUncollapse] = useState(uncollapseDefault || false);
  const [isTextShowOnClick, setTextShowOnClick] = useState(
    textFullQuote || false,
  );
  const [zoomIndex, setZoomIndex] = useState(-1);

  logT(
    "render",
    "article",
    quote ? "quote" : "post",
    data,
    "collapsed =",
    collapsed,
    "uncollapse =",
    uncollapse,
    "fullQuote =",
    isTextShowOnClick,
  );

  useDidUpdateEffect(() => {
    if (onUncollapse) onUncollapse(uncollapse);
  }, [uncollapse]);

  useDidUpdateEffect(() => {
    if (onCollapsed) onCollapsed();
  }, [collapsed]);

  useDidUpdateEffect(() => {
    if (onTextFullQuote) onTextFullQuote(isTextShowOnClick);
  }, [isTextShowOnClick]);

  const calcArticleCut = () => {
    if (!collapsable) return;
    if (!articleBody?.current) return;

    const articleHeight = articleBody.current.offsetHeight;
    if (articleHeight > (preview ? 500 : 1000)) {
      setCollapsed(true);
    }
  };

  useLayoutEffect(() => {
    if (maxTextSize > 0 && onRenderFinish) {
      if (!window.articleRender) window.articleRender = 0;
      window.articleRender++;
    }
  }, []);

  useEffect(() => {
    calcArticleCut();

    if (maxTextSize > 0 && onRenderFinish) {
      if (typeof window.articleRender != "undefined") {
        if (--window.articleRender == 0) {
          logT("article", "finish render all articles");
          onRenderFinish();
        }
      }
    }
  }, []);

  // Process and filter blocks only once
  const processBlocks = () => {
    let blocks = data?.blocks;
    if (!blocks || !Array.isArray(blocks) || blocks.length === 0) {
      return { blocks: [], structuredData: null };
    }

    // Apply filters
    if (filterType && filterType.length > 0) {
      blocks = blocks.filter((block) => filterType.includes(block.type));
    }
    if (quote) {
      blocks = blocks.filter((block) => block.type === "text");
    }

    if (blocks.length === 0) {
      return { blocks: [], structuredData: null };
    }

    // Collect metadata in a single pass
    const metadata = {
      firstImage: null,
      firstTextBlock: null,
      firstYoutubeBlock: null,
      firstVideoPreviewImage: null,
      firstImageBlockIndex: -1,
      lastImageBlockIndex: -1,
      textBlocks: 0,
      imageBlocks: 0,
      videoBlocks: 0,
      youtubeBlocks: 0,
      otherVideoBlocks: 0,
      mediaItems: [],
      textContent: [],
      imageItems: [],
      videoItems: [],
    };

    blocks.forEach((block, index) => {
      // Track block types for structured data
      switch (block.type) {
        case "text":
          metadata.textBlocks++;

          let text;
          if (block.data) {
            text = block.data
              // First, replace common HTML elements with spaces in one step
              .replace(/<div>|&nbsp;|\n/g, " ")
              // Then remove all HTML tags in one pass
              .replace(/<[^>]*>?/gm, "")
              // Remove zero-width and invisible characters
              .replace(/[\u200B-\u200D\uFEFF]/g, "")
              // Replace multiple spaces with a single space (more efficient than repeated replacements)
              .replace(/\s+/g, " ")
              .trim();
          }

          // Push metadata.textContent to text
          if (text) {
            metadata.textContent.push(text);
          }

          // Capture first text block
          if (!metadata.firstTextBlock && onFirstTextBlock && text) {
            metadata.firstTextBlock = text;
          }
          break;

        case "image":
          metadata.imageBlocks++;

          // Track image indices for navigation
          if (metadata.firstImageBlockIndex === -1) {
            metadata.firstImageBlockIndex = index;
          }
          metadata.lastImageBlockIndex = index;

          // Capture first non-adult image
          if (
            !metadata.firstImage &&
            (!noFirstImageAdult || !block.adult) &&
            block.file
          ) {
            metadata.firstImage =
              "https://talkvio.com" + "/file/" + data.userid + "/" + block.file;
          }

          // Add to structured data
          if (block.file && data.userid) {
            const imageUrl = `https://talkvio.com/file/${data.userid}/${block.file}`;
            const item = {
              "@type": "ImageObject",
              contentUrl: imageUrl,
              creditText:
                block.description ||
                (description ? `${description} #${index + 1}` : null),
              caption: block.description,
            };
            metadata.imageItems.push(item);
            metadata.mediaItems.push(item);
          }
          break;

        case "video":
        case "animation":
          metadata.videoBlocks++;

          // Capture first video preview
          if (!metadata.firstVideoPreviewImage && block.file) {
            metadata.firstVideoPreviewImage =
              "https://talkvio.com" +
              "/file/thumbnail/" +
              data.userid +
              "/" +
              block.file;
          }

          // Add to structured data
          if (block.file && data.userid) {
            const videoUrl = `https://talkvio.com/file/${data.userid}/${block.file}`;
            const thumbnailUrl = `https://talkvio.com/file/thumbnail/${data.userid}/${block.file}`;
            const item = {
              "@type": "VideoObject",
              name:
                block.description ||
                description ||
                `Video by user ${data.userid}`,
              contentUrl: videoUrl,
              thumbnailUrl: thumbnailUrl,
              description: block.description || description,
              uploadDate: dateline
                ? new Date(dateline * 1000).toISOString()
                : new Date().toISOString(),
            };
            metadata.videoItems.push(item);
            metadata.mediaItems.push(item);
          }
          break;

        case "youtube":
          metadata.youtubeBlocks++;

          // Capture first youtube block
          if (!metadata.firstYoutubeBlock && block.data) {
            metadata.firstYoutubeBlock = block.data;
          }

          // Add to structured data
          if (block.data) {
            const youtubeId = block.data;
            const item = {
              "@type": "VideoObject",
              name: description || `YouTube video ${youtubeId}`,
              contentUrl: `https://www.youtube.com/watch?v=${youtubeId}`,
              embedUrl: `https://www.youtube.com/embed/${youtubeId}`,
              thumbnailUrl: `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`,
              description: description || "",
              uploadDate: dateline
                ? new Date(dateline * 1000).toISOString()
                : new Date().toISOString(),
            };
            metadata.mediaItems.push(item);
            metadata.videoItems.push(item);
          }
          break;

        case "twitch":
        case "coub":
        case "rutube":
        case "vk":
          metadata.otherVideoBlocks++;
          break;
      }
    });

    // Call callbacks with collected data
    if (metadata.firstTextBlock && onFirstTextBlock) {
      onFirstTextBlock(metadata.firstTextBlock);
    }
    if (metadata.firstImage && onFirstImage) {
      onFirstImage(metadata.firstImage);
    }
    if (
      !metadata.firstImage &&
      metadata.firstYoutubeBlock &&
      onFirstYoutubeBlock
    ) {
      onFirstYoutubeBlock(metadata.firstYoutubeBlock);
    }
    if (
      onFirstVideoPreviewImage &&
      metadata.firstVideoPreviewImage &&
      !metadata.firstImage &&
      !metadata.firstYoutubeBlock
    ) {
      onFirstVideoPreviewImage(metadata.firstVideoPreviewImage);
    }

    // Generate structured data if needed
    const structuredData =
      !preview && !quote && postid ? generateStructuredData(metadata) : null;

    return {
      blocks,
      metadata,
      structuredData,
    };
  };

  // Generate structured data based on collected metadata
  const generateStructuredData = (metadata) => {
    /*
    const totalMediaBlocks =
      metadata.imageBlocks +
      metadata.videoBlocks +
      metadata.youtubeBlocks +
      metadata.otherVideoBlocks;

    // Determine the dominant content type
    const isDominantlyVideo =
      metadata.videoBlocks +
        metadata.youtubeBlocks +
        metadata.otherVideoBlocks >
        metadata.imageBlocks && totalMediaBlocks > metadata.textBlocks;

    const isDominantlyImage =
      metadata.imageBlocks >
        metadata.videoBlocks +
          metadata.youtubeBlocks +
          metadata.otherVideoBlocks &&
      metadata.imageBlocks > metadata.textBlocks;
    */

    const structuredData = {
      "@context": "https://schema.org",
      "@id": `${window.location.origin}/post/${postid}`,
    };

    structuredData.text = metadata.textContent.join(" ").substring(0, 65536);

    if (metadata.imageItems.length > 0) {
      structuredData.image = metadata.imageItems;
    }
    if (metadata.videoItems.length > 0) {
      structuredData.video = metadata.videoItems;
    }

    return structuredData;
  };

  // Process blocks and generate metadata in one pass
  const { blocks, metadata, structuredData } = processBlocks();

  // If no blocks after filtering, return appropriate component
  if (!blocks || blocks.length === 0) {
    if (quote) {
      return (
        <div className="no-text">
          <i>Прикрепленная картинка или видео</i>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      {structuredData && (
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
      <div
        ref={articleBody}
        className={
          "pagetext article" +
          (className ? ` ${className}` : "") +
          (collapsed && !uncollapse ? " collapsed" : "") +
          (quote ? " quote" : "") +
          (maxTextShowOnClick && maxTextSize && !isTextShowOnClick
            ? " pointer"
            : "")
        }
        onClick={() => {
          if (!maxTextShowOnClick) return;
          if (!maxTextSize) return;
          if (isTextShowOnClick) return;

          logT(
            "article",
            "show full text because of maxTextShowOnClick",
            sharedObject?.current,
          );
          setTextShowOnClick(true);
        }}
      >
        {blocks.map((block, i) => {
          // Skip adult or spoiler blocks in text view as they're converted to buttons
          if (maxTextSize > 0) {
            if (block.spoiler) {
              return (
                <div key={i} className="block text">
                  <i>(содержит спойлер)</i>
                </div>
              );
            }
            if (block.adult) {
              return (
                <div key={i} className="block text">
                  <i>(содержит 18+)</i>
                </div>
              );
            }
          }

          return (
            <Block
              key={i}
              index={i}
              block={block}
              userid={data.userid}
              maxTextSize={maxTextSize}
              isTextShowOnClick={isTextShowOnClick}
              maxTextShowOnClick={maxTextShowOnClick}
              convertToText={convertToText}
              sharedObject={sharedObject?.current}
              description={description}
              dateline={dateline}
              onRenderFinish={onRenderFinish}
              zoomIndex={zoomIndex}
              onCloseZoom={() => {
                logT("zoom", "zoom closed, reset index");
                setZoomIndex(-1);
              }}
              onPrevPicture={
                metadata.firstImageBlockIndex !== -1 &&
                i > metadata.firstImageBlockIndex
                  ? () => {
                      for (let j = i - 1; j >= 0; j--) {
                        if (blocks[j].type === "image") {
                          logT("picture", "previus prev picture block", j);
                          setZoomIndex(j);
                          break;
                        }
                      }
                    }
                  : null
              }
              onNextPicture={
                metadata.lastImageBlockIndex !== -1 &&
                i < metadata.lastImageBlockIndex
                  ? () => {
                      for (let j = i + 1; j < blocks.length; j++) {
                        if (blocks[j].type === "image") {
                          logT("picture", "show next picture block", j);
                          setZoomIndex(j);
                          break;
                        }
                      }
                    }
                  : null
              }
            />
          );
        })}
        {collapsed && !uncollapse ? (
          <>
            <div className="cut-off" onClick={() => setUncollapse(true)}></div>
            <div className="showFullPost" onClick={() => setUncollapse(true)}>
              {__("Show in full")}
            </div>
          </>
        ) : null}
        {collapsed && uncollapse ? (
          <>
            <div
              className="showFullPost collapse"
              onClick={() => setUncollapse(false)}
            >
              {__("Collapse")}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Article;

export { articleObject };

let lazysNow = {};
let lazysTimesout = {};

const LazyCall = (lazyname, fun, time) => {
  // Support for auto-generating a lazyname if not provided
  if (typeof lazyname === "function" && typeof fun === "number") {
    // When called with (fun, time) pattern, generate unique lazyname
    time = fun;
    fun = lazyname;
    lazyname = "_lazy_" + Math.random().toString(36).substring(2, 9);
  }

  if (!lazysNow[lazyname]) {
    lazysNow[lazyname] = 0;
  }
  const now = Date.now();
  const diff = now - lazysNow[lazyname];
  //logT("lazy", "diff", diff, "left", diff >= time ? "now" : time - diff);
  if (diff >= time) {
    // Clear any existing timeout regardless of execution path
    if (lazysTimesout[lazyname]) {
      clearTimeout(lazysTimesout[lazyname]);
      lazysTimesout[lazyname] = null;
    }
    fun();
    //logT("lazy-done", "trigger now");
    lazysNow[lazyname] = Date.now();
  } else {
    if (lazysTimesout[lazyname]) clearTimeout(lazysTimesout[lazyname]);
    lazysTimesout[lazyname] = setTimeout(() => {
      fun();
      //logT("lazy-done", "trigger delayed");
      lazysNow[lazyname] = Date.now();
      lazysTimesout[lazyname] = null;
    }, time - diff);
  }

  // Return cleanup function, perfect for useEffect
  return () => {
    if (lazysTimesout[lazyname]) {
      clearTimeout(lazysTimesout[lazyname]);
      lazysTimesout[lazyname] = null;
    }
  };
};

export { LazyCall };

import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { LoginContext } from "./Login";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faBookmark,
  faComments,
  faGear,
  faRss,
  faRightToBracket,
  faPlus,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import "./UserInfoPanel.css";

const UserInfoPanel = () => {
  const { user } = useContext(LoginContext);
  const location = useLocation();

  const navLinks = user
    ? [
        {
          to: "#",
          icon: faPlus,
          label: __("Create post"),
          onClick: (e) => {
            e.preventDefault();
            if (window.openCloseEditor) {
              window.openCloseEditor();
            }
          },
        },
        { to: "/subscriptions", icon: faRss, label: __("Subscriptions") },
        { to: "/answers", icon: faCommentDots, label: __("Answers") },
        { to: "/bookmarks", icon: faBookmark, label: __("Bookmarks") },
        { to: "/liked", icon: faTrophy, label: __("Liked posts") },
        { to: "/mycomments", icon: faComments, label: __("My comments") },
        { to: "/settings", icon: faGear, label: __("Settings") },
      ]
    : [
        {
          to: "#",
          icon: faRightToBracket,
          label: __("Login") + " / " + __("Registation"),
          onClick: (e) => {
            e.preventDefault();
            window.needRegistationAction(true);
          },
        },
      ];

  const handleGuestClick = (e) => {
    e.preventDefault();
    window.needRegistationAction(true);
  };

  const isLinkActive = (path) => {
    if (path === "#") return false;
    return location.pathname === path;
  };

  return (
    <div className="typicalBlock userInfoPanel">
      <div className="userPanelAvatar">
        {user ? (
          <Link to={`/${user.username}`}>
            <img
              src={
                user.avatarrevision > 0
                  ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                  : DefaultAvatar_150
              }
              alt={`${user.username} avatar`}
            />
          </Link>
        ) : (
          <a href="#" onClick={handleGuestClick}>
            <img
              src={DefaultAvatar_150}
              alt={__("Guest avatar")}
              className="guest"
            />
          </a>
        )}
      </div>
      <div className="userPanelInfo">
        {user ? (
          <Link to={`/${user.username}`} className="username">
            {user.username}
          </Link>
        ) : (
          <a href="#" onClick={handleGuestClick} className="username guest">
            {__("Guest")}
          </a>
        )}
        <div className="stats">
          {user ? (
            <>
              <div className="statItem">
                <div className="value">{user.subscribers}</div>
                <div className="label">{__("Subscribers")}</div>
              </div>
              <div className="statItem">
                <div className="value">{user.reputation}</div>
                <div className="label">{__("Reputation")}</div>
              </div>
              <div className="statItem">
                <div className="value">{user.karma}</div>
                <div className="label">{__("Karma")}</div>
              </div>
            </>
          ) : (
            <div className="guestMessage">
              {__("Login to access all features")}
            </div>
          )}
        </div>
      </div>
      <nav className="userPanelNav">
        {navLinks.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={`navLink${link.icon === faPlus ? " createPost" : ""}${
              isLinkActive(link.to) ? " active" : ""
            }`}
            onClick={link.onClick}
          >
            <FontAwesomeIcon icon={link.icon} />
            <span>{link.label}</span>
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default UserInfoPanel;

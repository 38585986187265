import { v4 as uuidv4 } from "uuid";

const getStorageValue = (key, options = {}) =>
  new Promise((resolve) => {
    const indexedDBValue = () => {
      const dbName = "talkvio";
      const dbVersion = 1;
      const request = indexedDB.open(dbName, dbVersion);
      request.onerror = (event) => {
        console.error("Database error:", event.target.error);
        resolve(null);
      };
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        const objStore = db.createObjectStore("talkvio", {});
      };
      request.onsuccess = async (event) => {
        const db = event.target.result;
        const request = await db
          .transaction(["talkvio"], "readwrite")
          .objectStore("talkvio")
          .get(key);
        request.onsuccess = () => {
          resolve(request.result);
        };
        request.onerror = () => {
          resolve(null);
        };
      };
    };

    if (!options.storage || options.storage === "localstorage") {
      if (!window.localStorage) {
        logT("storage", "localStorage is unavailable.");
        resolve(null);
        return;
      }

      const localStoreValue = window.localStorage.getItem(key);
      if (localStoreValue) {
        try {
          resolve(JSON.parse(localStoreValue));
        } catch (e) {
          logTE("storage", "Error parsing localStorage value");
          resolve(null);
        }
        return;
      } else if (!options.storage) {
        indexedDBValue();
      } else {
        logT("storage", "No value found for key", key);
        resolve(null);
      }
    } else if (!options.storage || options.storage === "indexeddb") {
      indexedDBValue();
    } else {
      logTE("storage", "Invalid storage option");
      resolve(null);
    }
  });

const getStorageValueSync = (key, options = {}) => {
  // This sync version only works with localStorage
  // IndexedDB operations are inherently asynchronous
  if (options.storage === "indexeddb") {
    console.warn(
      "getStorageValueSync cannot use IndexedDB. Falling back to localStorage.",
    );
  }

  if (!window.localStorage) {
    logT("storage", "localStorage is unavailable.");
    return null;
  }

  const localStoreValue = window.localStorage.getItem(key);
  if (localStoreValue) {
    try {
      return JSON.parse(localStoreValue);
    } catch (e) {
      logTE("storage", "Error parsing localStorage value");
      return null;
    }
  }

  return null;
};

const setStorageValue = (key, value, options = {}) => {
  const storage = window.localStorage;
  // Check if localStorage is available
  if (!storage) {
    logT("storage", "localStorage is unavailable.");
    return;
  }

  const storeIndexDB = () => {
    // clear this key from localstorage
    window.localStorage.removeItem(key);

    const dbName = "talkvio";
    const dbVersion = 1;
    const request = indexedDB.open(dbName, dbVersion);
    request.onerror = (event) => {
      console.error("Database error:", event.target.error);
    };
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      const objStore = db.createObjectStore("talkvio", {});
    };
    request.onsuccess = (event) => {
      const db = event.target.result;
      const request = db
        .transaction(["talkvio"], "readwrite")
        .objectStore("talkvio")
        .put(value, key);
    };
  };

  // local storage by default
  if (!options.storage || options.storage === "localstorage") {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      // clear indexDB value if exists
      if (options.storage !== "localstorage" && indexedDB) {
        const dbName = "talkvio";
        const dbVersion = 1;
        const request = indexedDB.open(dbName, dbVersion);
        request.onerror = (event) => {
          console.error("Database error:", event.target.error);
        };
        request.onupgradeneeded = (event) => {
          const db = event.target.result;
          const objStore = db.createObjectStore("talkvio", {});
        };
        request.onsuccess = (event) => {
          const db = event.target.result;
          const request = db
            .transaction(["talkvio"], "readwrite")
            .objectStore("talkvio")
            .delete(key);
        };
      }
    } catch (err) {
      if (!options.storage) {
        storeIndexDB();
      } else {
        logTE("storage", "Error setting localStorage value");
      }
    }
  } else if (!options.storage || options.storage === "indexeddb") {
    storeIndexDB();
  } else {
    logTE("storage", "Invalid storage option");
  }
};

const clearStorageValue = (key) => {
  const storage = window.localStorage;
  // Check if localStorage is available
  if (!storage) {
    logT("storage", "localStorage is unavailable.");
    return;
  }

  window.localStorage.removeItem(key);
  const dbName = "talkvio";
  const dbVersion = 1;
  const request = indexedDB.open(dbName, dbVersion);
  request.onerror = (event) => {
    console.error("Database error:", event.target.error);
  };
  request.onupgradeneeded = (event) => {
    const db = event.target.result;
    const objStore = db.createObjectStore("talkvio", {});
  };
  request.onsuccess = (event) => {
    const db = event.target.result;
    const request = db
      .transaction(["talkvio"], "readwrite")
      .objectStore("talkvio")
      .delete(key);
  };
};

const lazyStorages = {};
const lazyStorageUse = (storageKey = "readed", options = {}) => {
  if (lazyStorages[storageKey]) {
    if (options.reload) {
      logT("lazyStorage", "reload", storageKey);
      lazyStorages[storageKey].reload();
    }
    return lazyStorages[storageKey];
  }

  let memoryStore = {};
  const reload = () => {
    try {
      Object.assign(
        memoryStore,
        JSON.parse(window.localStorage.getItem(storageKey)) || {},
      );
    } catch (e) {}
  };
  reload();

  logT("lazyStorage", "memoryStore initialized for", storageKey);
  let saveTimeout = null;
  let lastSaveTime = 0;
  const tabId = uuidv4();

  let bc = null;
  if (typeof BroadcastChannel !== "undefined") {
    bc = new BroadcastChannel("talkvio_lazy_storage_" + storageKey);
    bc.onmessage = (event) => {
      if (event.data.tabId === tabId) {
        return;
      }

      if (event.data.type === "reload") {
        logT("lazyStorage", "tab reload event", storageKey);
        reload();
      }
    };
  }

  const destroy = () => {
    logT("lazyStorage", "destroy", storageKey);
    bc?.close();
    delete lazyStorages[storageKey];
  };

  const updateData = (target, prop, value) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    const currentTime = Date.now();
    const timeSinceLastSave = currentTime - lastSaveTime;
    const timeoutDuration = Math.max(
      0,
      (options.interval || 300) - timeSinceLastSave,
    ); // 300ms

    saveTimeout = setTimeout(async () => {
      logT(
        "lazyStorage",
        "saving to local storage",
        "storageKey",
        storageKey,
        "prop",
        prop,
        "value",
        value,
      );
      await setStorageValue(storageKey, target, { storage: "localstorage" });
      lastSaveTime = Date.now();
      bc?.postMessage({ type: "reload", tabId });
    }, timeoutDuration);
  };

  const lazyStorage = new Proxy(memoryStore, {
    get(target, prop) {
      return target[prop];
    },
    set(target, prop, value) {
      target[prop] = value;
      updateData(target, prop, value);
      return true;
    },
    deleteProperty(target, prop) {
      delete target[prop];
      updateData(target, prop, null);
      return true;
    },
  });

  lazyStorages[storageKey] = {
    proxy: lazyStorage,
    reload: reload,
    destroy: destroy,
  };
  return lazyStorages[storageKey];
};

export {
  getStorageValue,
  getStorageValueSync,
  setStorageValue,
  clearStorageValue,
  lazyStorageUse,
};

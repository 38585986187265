import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import { transliterateLink } from "../global/Global";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faEye } from "@fortawesome/free-solid-svg-icons";

const Thread = ({ thread }) => {
  return (
    <li className="thread">
      <div className="threadinfo">
        <div className="title">
          <Link
            to={
              "/threads/" +
              thread.threadid +
              `-${transliterateLink(thread.title)}`
            }
          >
            {thread.title}
          </Link>
        </div>
        <div className="poster">
          <Link to={"/user/" + thread.postuserid}>{thread.postusername}</Link>
          <span>•</span>
          <span>{moment(thread.dateline * 1000).fromNow()}</span>
        </div>
      </div>
      <div className="statistic">
        <div className="replycount">
          <FontAwesomeIcon icon={faComments} /> {thread.replycount}
        </div>
        <div className="views">
          <FontAwesomeIcon icon={faEye} /> {thread.views}
        </div>
      </div>
      <div className="lastpostinfo">
        <div className="lastposter">
          <img
            src={
              thread.lastposteravrev > 0
                ? `https://talkvio.com/customavatars/avatar${thread.lastposterid}_${thread.lastposteravrev}.gif`
                : DefaultAvatar_150
            }
            alt={__("Avatar of user") + " " + thread.lastposter}
          />
          <Link to={"/user/" + thread.lastposterid}>{thread.lastposter}</Link>
        </div>
        <div className="lastposttime">
          {moment(thread.lastposttime * 1000).fromNow()}
        </div>
      </div>
    </li>
  );
};

export default Thread;

import React, { useEffect, useState } from "react";
import "./Checkbox.css";
import "./Input.css";

export default ({
  value,
  placeholder,
  className,
  style,
  onBlur,
  onChange,
  placeholderLeft,
  placeholderLeftText,
  inputClassName,
  password,
  newPassword,
  onKeyPress,
  leftPanel,
  rightPanel,
  onKeyDown,
}) => {
  const [text, setText] = React.useState(value || "");

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div
      onKeyPress={onKeyPress}
      className={"text-input" + (className ? ` ${className}` : "")}
    >
      {placeholderLeft ? (
        <span className="placeholder">
          {placeholderLeftText || placeholder}:{" "}
        </span>
      ) : null}
      {leftPanel}
      <input
        type={password ? "password" : null}
        autoComplete={password && newPassword ? "new-password" : null}
        style={style}
        className={inputClassName ? inputClassName : ""}
        placeholder={placeholder}
        onInput={(e) => setText(e.target.value)}
        value={text || ""}
        onKeyDown={onKeyDown}
        onBlur={(e) => {
          if (onBlur) onBlur(e.target.value);
        }}
        onChange={(e) => {
          if (onChange) onChange(e.target.value);
        }}
      />
      {rightPanel}
    </div>
  );
};

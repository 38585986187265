import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { useLocation } from "react-router-dom";
import { LoginContext } from "./Login";
import RecentPosts from "./RecentPosts";
import Settings from "./Settings";

const SettingsPage = () => {
  const { state } = useLocation();
  const { user, reloadUserInfo } = useContext(LoginContext);
  const saveDateRef = useRef();
  const reloadUserInfoOnce = useRef();

  useEffect(() => {
    if (!user) return;
    if (reloadUserInfoOnce?.current) return;

    reloadUserInfoOnce.current = true;
    reloadUserInfo();
    document.title = `${__("My settings")} - Talkvio`;
  }, [user]);

  useEffect(() => {
    window.setLeftPanelTopOffet(64);
    window.setLeftPanelDisplay(true);

    return () => {
      window.resetLeftPanelTopOffet();
      window.setLeftPanelDisplay(false);
    };
  }, []);

  if (!user) {
    return;
  }

  const showSaveDate = async (date) => {
    if (!saveDateRef.current) return;

    saveDateRef.current.innerText = `${__("Settings saved")} ${moment(date).format("MMM Do hh:mm:ss")}`;
    saveDateRef.current.classList.remove("animate-top");
    void saveDateRef.current.offsetWidth;
    saveDateRef.current.classList.add("animate-top");
  };

  return (
    <div className="settingsPage typicalBlock">
      <Settings
        user={user}
        avatarSettings={true}
        custombackSettings={true}
        onSuccess={(data) => {
          showSaveDate(Date.now());
        }}
      />
      <div className="saveDate" ref={saveDateRef}></div>
    </div>
  );
};
export default SettingsPage;

import React from "react";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import DefaultAvatar_150 from "../default-avatar_150.png";
import DefaultCustomBack from "../default-customback.webp";
import { getCookie } from "../global/Global";
import Tooltip from "./Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faUser,
  faVenus,
  faCalendarAlt,
  faClock,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import "./UserBlock.css";

const UserBlock = ({ user, myUser }) => {
  const isMyProfile = myUser?.userid === user.userid;

  return (
    <li className="userBlock">
      <div className="customback">
        <img
          src={
            user.customback
              ? `https://talkvio.com/customback/customback_${user.userid}.webp`
              : DefaultCustomBack
          }
          alt={"Background for " + user.username}
        />
      </div>

      <div className="avatar">
        <Link to={"/user/" + user.userid}>
          <img
            src={
              user.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${user.userid}_${user.avatarrevision}.gif`
                : DefaultAvatar_150
            }
            alt={"Аватар пользователя " + user.username}
          />
        </Link>
      </div>

      <div className="username">
        <Link to={"/user/" + user.userid}>
          {user.username}
          {user.gender && user.gender !== "none" ? (
            <sup className="gender">
              {user.gender == "male" ? (
                <FontAwesomeIcon className="male" icon={faMars} />
              ) : null}
              {user.gender == "female" ? (
                <FontAwesomeIcon className="female" icon={faVenus} />
              ) : null}
            </sup>
          ) : null}
        </Link>
      </div>

      {isMyProfile ? (
        <div className="self-profile-badge">
          <FontAwesomeIcon icon={faUserCheck} />
          <span>{__("Your Profile")}</span>
        </div>
      ) : myUser?.userid > 0 ? (
        <div
          className={
            "clickButton subscriptionButtonBlock" +
            (user.subscriptionid > 0 ? " unsubscribe" : " subscribe")
          }
          style={{ cursor: "pointer" }}
          onClick={async () => {
            const token = getCookie("token");
            const userid = parseInt(getCookie("userid"));
            const data = await window.TALKVIOAPI("subscribe", {
              userid,
              token,
              targetuserid: user.userid,
            });
            window.displayError(data);
          }}
        >
          {user.subscriptionid > 0 ? __("Unsubscribe") : __("Subscribe")}
        </div>
      ) : null}
      <div className="user-metadata">
        <div className="date-chip">
          <FontAwesomeIcon icon={faCalendarAlt} />
          <span className="date-label">{__("Joined")}:</span>
          <span className="date-value">
            {moment(user.joindate * 1000).format("MMM YYYY")}
          </span>
          <span className="date-relative">
            ({moment(user.joindate * 1000).fromNow(true)})
          </span>
        </div>

        <div className="date-chip">
          <FontAwesomeIcon icon={faClock} />
          <span className="date-label">{__("Activity")}:</span>
          <span className="date-relative">
            {moment(user.lastactivity * 1000).fromNow()}
          </span>
        </div>
      </div>

      <div className="user-stats">
        <div className="infoblock">
          <div className="info-element posts">
            <div className="value">{user.posts}</div>
            <div className="desc">{__("Records")}</div>
          </div>
          <div className="info-element reputation">
            <div className="value">{user.reputation}</div>
            <div className="desc">{__("Reputation")}</div>
          </div>
          <Tooltip
            top={true}
            offsetX={10}
            hint={__(
              "Karma is displaying how much other users approves or like this user or user actions. Only registred people with karma > 5 itself can change this value.",
            )}
          >
            <div className="info-element karma">
              <div className="value">
                <FontAwesomeIcon icon={faHeart} />
                <span>{user.karma}</span>
              </div>
              <div className="desc">{__("Karma")}</div>
            </div>
          </Tooltip>
          <div className="info-element subscribers">
            <div className="value">
              <FontAwesomeIcon icon={faUser} />
              <span>{user.subscribers}</span>
            </div>
            <div className="desc">{__("Subscribers")}</div>
          </div>
        </div>
      </div>
    </li>
  );
};
export default UserBlock;

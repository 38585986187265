import "./App.css";
import { useEffect, useLayoutEffect, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import Forums from "./components/Forums.js";
import Threads from "./components/Threads.js";
import Posts from "./components/Posts.js";
import Login from "./components/Login.js";
import RestorePassword from "./components/RestorePassword";
import ScreenMessage from "./components/ScreenMessage";
import { Link } from "react-router-dom";
import ConfirmPassword from "./components/ConfirmPassword";
import Activity from "./components/Activity";
import Popular from "./components/Popular";
import Footer from "./components/Footer";
import User from "./components/User";
import Header from "./components/Header";
import {
  getNoficiationsPermission,
  sendNotification,
} from "./global/Notifications";
import Users from "./components/Users";
import SpecialPage from "./components/SpecialPage";
import SearchPage from "./components/SearchPage";
import Translations from "./components/Translations";
import {
  getConnectionQuality,
  getCookie,
  setCookie,
  sleep,
  storeReferralParams,
} from "./global/Global";
import Subscriptions from "./components/Subscriptions";
import Original from "./components/Original";
import DelayedPosts from "./components/DelayedPosts";
import Main from "./components/Main";
import VKLogin from "./components/VKLogin";
import MostCommented from "./components/MostCommented";
import Tag from "./components/Tag";
import useDidUpdateEffect from "./global/DidUpdateEffect";
import Threme from "./components/Theme";
import Bookmarks from "./components/Bookmarks";
import SettingsPage from "./components/SettingsPage";
import NotFoundPage from "./components/NotFoundPage";
import ConfirmMessage from "./components/ConfirmMessage";
import Random from "./components/Random";
import RemoveAccount from "./components/RemoveAccount";
import Answers from "./components/Answers";
import MyComments from "./components/MyComments";
import Liked from "./components/Liked";
import RightPanel from "./components/RightPanel.js";
import Keys from "./components/Keys.js";
import LeftPanel from "./components/LeftPanel.js";
import Section from "./components/Section.js";
import Overlay from "./components/Overlay.js";
import Blocks from "./components/Blocks";
import GoogleAuthPage from "./components/GoogleAuthPage";
import { lazyStorageUse } from "./global/Storage";
window.loadingFinished = -1;
window.loadingMap = {};
window.loadingStart = (key) => {
  if (key) {
    if (window.loadingMap[key]) return;
    else window.loadingMap[key] = true;
  }

  if (window.loadingFinished == -1)
    window.loadingFinished = window.loadingFinished + 2;
  else window.loadingFinished++;

  logT("loading", "start", window.loadingFinished, "key", key);
};
window.loadingFinish = (key) => {
  if (key) {
    if (!window.loadingMap[key]) return;
    else delete window.loadingMap[key];
  }

  window.loadingFinished--;

  logT("loading", "finish", window.loadingFinished, "key", key);
};

const socket = io();
let talkvioHeartbeat = -1;

socket.on("connect", () => {
  if (socket.recovered) {
    logT("socket", "socket reconnected");
  } else {
    logT("socket", "socket connected");
  }
  talkvioHeartbeat = Date.now();
});

socket.on("disconnect", (reason) => {
  logT("socket", "socket disconnect: " + reason);
  talkvioHeartbeat = -1;
});

socket.on("connect_error", (error) => {
  if (socket.active) {
    // temporary failure, the socket will automatically try to reconnect
  } else {
    // the connection was denied by the server
    // in that case, `socket.connect()` must be manually called in order to reconnect
    logTW("socket", "socket connection error", error.message);
  }
});

socket.io.engine.on("heartbeat", () => {
  talkvioHeartbeat = Date.now();
});

const checkTalkvioHeartbeat = () => {
  if (talkvioHeartbeat == -1) return;
  if (!socket.connected) return;
  const diff = Date.now() - talkvioHeartbeat;
  if (diff <= 50000) {
    return;
  }
  logTW("socket", "talkvio heartbeat timeout", diff);
  socket.disconnect();
  socket.connect();
};

document.addEventListener("visibilitychange", () => {
  if (document.visibilityState === "visible") {
    logT("talkvio", "visibility change to visible");
    checkTalkvioHeartbeat();
  }
});

window.TAKVIO_API_TAGS = {};

window.TALKVIOAPI = (apiPoint, data, options = {}) =>
  new Promise(async (resolve) => {
    if (apiPoint != "logNetwork") {
      window.loadingStart();
    }
    checkTalkvioHeartbeat();
    const speedQuality = getConnectionQuality(apiPoint == "logNetwork");
    if (apiPoint != "logNetwork") {
      logT(
        "api",
        apiPoint + (options.mark ? ` (${options.mark})` : ""),
        "transport",
        socket?.io?.engine?.transport?.name,
        "speed quality",
        speedQuality,
        data,
      );
    }
    if (options?.before) options.before();
    let id;
    if (options.tag) {
      // override previous request with same tag
      if (options.override) {
        window.TALKVIOAPI_CANCEL(options.tag);
      }

      if (!window.TAKVIO_API_TAGS[options.tag]) {
        window.TAKVIO_API_TAGS[options.tag] = [];
      }
      id =
        (Math.random() + 1).toString(36).substring(7) +
        (Math.random() + 1).toString(36).substring(7);
      window.TAKVIO_API_TAGS[options.tag].push(id);
    }

    let chunkId;
    if (options.chunkMode) {
      let retry = -1;
      let needRetry = true;
      let retryAttempts = 3;
      while (++retry < retryAttempts && needRetry) {
        chunkId =
          (Math.random() + 1).toString(36).substring(7) +
          (Math.random() + 1).toString(36).substring(7);

        const firstPacketTimeouts = [11, 35, 35];
        const otherPacketTimeouts = [30, 40, 40];
        const speedQualityIndex =
          retry == 0
            ? [
                256 * 1024,
                384 * 1024,
                512 * 1024,
                3 * 1024 * 1024,
                4 * 1024 * 1024,
              ]
            : retry == 1
              ? [
                  // much smaller for second attempt
                  256 * 1024,
                  256 * 1024,
                  256 * 1024,
                  256 * 1024,
                  256 * 1024,
                ]
              : [64 * 1024, 64 * 1024, 64 * 1024, 64 * 1024, 64 * 1024];
        const chunkSpliSize =
          (window.talkvioChunkSize &&
            Math.max(
              Math.min(window.talkvioChunkSize, 4 * 1024 * 1024),
              128 * 1024,
            )) ||
          speedQualityIndex[speedQuality] ||
          4 * 1024 * 1024;
        let chunksData = new TextEncoder().encode(JSON.stringify(data));
        // data = null;
        let chunkSize = chunksData.length;
        const chunks = [];
        let sindex = 0;
        while (sindex < chunkSize) {
          const chunk = chunksData.subarray(sindex, sindex + chunkSpliSize);
          chunks.push(chunk);
          sindex += chunk.length;
        }
        chunksData = null;
        if (apiPoint != "logNetwork") {
          logT(
            "chunk",
            "sending request by chunks",
            chunkId,
            "chunks number:",
            chunks.length,
            "size:",
            chunkSize,
            "split size:",
            chunkSpliSize,
            "retry:",
            retry,
          );
        }
        for (let chunkIndex = 0; chunkIndex < chunks.length; chunkIndex++) {
          const chunk = chunks[chunkIndex];
          const timeout =
            ((chunkIndex == 0
              ? firstPacketTimeouts[retry]
              : otherPacketTimeouts[retry]) || 25) * 1000;
          const responce = await new Promise((resolve) => {
            socket.timeout(timeout).emit(
              apiPoint,
              {
                chunkMode: true,
                chunkId,
                chunkIndex,
                chunkSize,
                chunkEachSize: chunkSpliSize,
                chunk,
              },
              (status, responce) => {
                if (status instanceof Error) {
                  socket.disconnect();
                  const onConnected = async () => {
                    socket.off("connect", onConnected);

                    // perform ping just to ensure connection is ok
                    for (let i = 0; i < 10; i++) {
                      const before = performance.now();
                      const pong = await window.TALKVIOAPI(
                        "ping",
                        {},
                        { timeout: 3000 },
                      );
                      const after = performance.now();
                      logT(
                        "chunk",
                        "ping before reconnection:",
                        (after - before).toFixed(1) + "ms",
                        "check",
                        i + 1,
                      );
                      if (pong?.pong && after - before < 3000) {
                        // ok
                        logT(
                          "chunk",
                          "connection is fine for retry",
                          retry + 1,
                        );
                        break;
                      }
                      await sleep(5000);
                    }

                    logTE("chunk", "chunk sending timeout, next retry");
                    logT("chunk", "started retry", retry + 1);
                    resolve({ timeoutError: true });
                  };
                  socket.on("connect", onConnected);
                  socket.connect();
                  return;
                }

                if (apiPoint != "logNetwork") {
                  logT(
                    "chunk",
                    "responce from",
                    chunkIndex,
                    "chunk id",
                    chunkId,
                    !responce?.systemError ? "success" : "fail",
                    "timeout",
                    timeout / 1000,
                  );
                }
                // reset heartbeat because we got responce
                talkvioHeartbeat = Date.now();
                resolve(responce);
              },
            );
          });
          if (responce?.systemError) {
            if (apiPoint != "logNetwork") {
              logTE("chunk", "error from chunk", chunkIndex);
            }
            if (responce.error) {
              resolve(responce);
            } else {
              resolve();
            }
            if (options?.after) options.after(responce);
            return;
          } else if (responce?.timeoutError) {
            needRetry = true;
            break;
          } else {
            needRetry = false;
            if (options.progress)
              options.progress((chunkIndex + 1) / chunks.length);
          }
        }
      }

      data = null;

      if (needRetry) {
        if (apiPoint != "logNetwork") {
          logTE("chunk", "error sending chunks by timeout");
        }
        const responce = {
          error: true,
          errorDesc: __("something wrong, please try again later"),
        };
        resolve(responce);
        if (options?.after) options.after(responce);
        return;
      }
    }

    (options.timeout ? socket.timeout(options.timeout) : socket).emit(
      apiPoint,
      !options.chunkMode ? data : { chunkId, chunkMode: true },
      (status, responce) => {
        if (!options.timeout) {
          responce = status;
        }
        if (apiPoint != "logNetwork") {
          logT(
            "api",
            apiPoint + (options.mark ? ` (${options.mark})` : ""),
            "responce",
            responce,
          );
        }
        if (options.timeout && status instanceof Error) {
          if (apiPoint != "logNetwork") {
            logTE(
              "api",
              apiPoint + (options.mark ? ` (${options.mark})` : ""),
              "timeout",
              options.timeout,
            );
          }
          resolve();
          if (options?.after) options.after();
          if (apiPoint != "logNetwork") {
            window.loadingFinish();
          }
          return;
        }
        if (options.tag) {
          let index = window.TAKVIO_API_TAGS[options.tag]?.indexOf(id);
          if (typeof index == "number" && index >= 0) {
            window.TAKVIO_API_TAGS[options.tag].splice(index, 1);
            if (window.TAKVIO_API_TAGS[options.tag].length == 0) {
              delete window.TAKVIO_API_TAGS[options.tag];
            }
            // override previous request with same tag
            if (options.override) {
              window.TALKVIOAPI_CANCEL(options.tag);
            }
          } else {
            if (apiPoint != "logNetwork") {
              logT(
                "api",
                apiPoint + (options.mark ? ` (${options.mark})` : ""),
                "request cancel",
              );
            }
            responce = null;
          }
        }
        if (options.progress) options.progress(1);
        resolve(responce);
        if (options?.after) options.after(responce);
        if (apiPoint != "logNetwork") {
          window.loadingFinish();
        }
      },
    );
  });

window.TALKVIOAPI_CANCEL = (tag) => {
  if (window.TAKVIO_API_TAGS[tag]) {
    delete window.TAKVIO_API_TAGS[tag];
    logT("api", "cancle api tag", tag);
  }
};

window.TALKVIO_ID_MAP = {};

window.TALKVIO_ON = (apiPoint, callback, id = null) => {
  if (!id) {
    return socket.on(apiPoint, callback);
  } else {
    if (!window.TALKVIO_ID_MAP[id + "_" + apiPoint]) {
      window.TALKVIO_ID_MAP[id + "_" + apiPoint] = callback;
      return socket.on(apiPoint, callback);
    } else {
      socket.off(apiPoint, window.TALKVIO_ID_MAP[id + "_" + apiPoint]);
      window.TALKVIO_ID_MAP[id + "_" + apiPoint] = callback;
      return socket.on(apiPoint, callback);
    }
  }
};
window.isTalkvioWebsocket = () =>
  socket?.io?.engine?.transport?.name == "websocket";

window._app_on_map = {};
window.APP_ON = (apiPoint, callback) => {
  if (!window._app_on_map[apiPoint]) window._app_on_map[apiPoint] = [];

  window._app_on_map[apiPoint].push(callback);
};
window.appJS = (apiPoint, data) => {
  if (
    !window._app_on_map[apiPoint] ||
    window._app_on_map[apiPoint].length == 0
  ) {
    return;
  }
  logT(
    "appAPI",
    "call",
    apiPoint,
    "with data",
    data?.toString().substring(0, 2048),
  );
  for (const callback of window._app_on_map[apiPoint]) {
    callback(data);
  }
};
window.APP_OFF = (apiPoint, callback) => {
  if (!window._app_on_map[apiPoint]) {
    logTW("appAPI", "no app off point", apiPoint);
    return;
  }

  if (!callback) {
    delete window._app_on_map[apiPoint];
    return;
  }

  const index = window._app_on_map[apiPoint].indexOf(callback);
  if (index > -1) {
    window._app_on_map[apiPoint].splice(index, 1);
    if (window._app_on_map[apiPoint].length == 0) {
      delete window._app_on_map[apiPoint];
    }
  } else {
    logTW("appAPI", "no callback func to cancel");
  }
};

function App() {
  window.loadingStart();
  const location = useLocation();

  let isCurrentBackward = useRef(false);
  useDidUpdateEffect(() => {
    setTimeout(() => {
      logT(
        "location",
        "change location",
        "location =",
        location.pathname,
        "key =",
        location.key,
        "backward =",
        isCurrentBackward.current,
      );
      const locationEvent = new CustomEvent("talkvioLocationChange", {
        detail: { backward: isCurrentBackward.current },
      });
      isCurrentBackward.current = false;
      window.dispatchEvent(locationEvent);
    }, 0);
  }, [location]);

  const calcRealRTT = async () => {
    const start = performance.now();
    const pong = await window.TALKVIOAPI("ping", {});
    if (pong.pong && !window.talkvioRealRTT) {
      const finish = performance.now();
      window.talkvioRealRTT = (finish - start) | 0;
      logT("ping", "rtt responce time", window.talkvioRealRTT);
    }
  };

  useEffect(() => {
    // Store referral parameters if present in URL
    storeReferralParams();

    window.addEventListener("popstate", () => {
      isCurrentBackward.current = true;
    });

    const myUserid = parseInt(getCookie("userid"));
    if (myUserid && myUserid > 0) {
      getNoficiationsPermission();
      window.TALKVIO_ON(
        "notification",
        ({ userid, sourceuserid, post }) => {
          if (post.readableText.includes("#nodgx")) {
            return;
          }

          const myUserid = parseInt(getCookie("userid"));
          if (!myUserid || myUserid != userid) {
            return;
          }

          sendNotification({
            title: __("New post from") + " " + post.username,
            message: post.readableText,
            icon:
              post.avatarrevision > 0
                ? `https://talkvio.com/customavatars/avatar${post.userid}_${post.avatarrevision}.gif`
                : null,
          });
        },
        "notifications",
      );
    }

    if (getCookie("screenlog")) {
      logT("console", "lgs on (show screen console)");
      document.getElementById("lgses").style.display = "block";
    }

    setTimeout(calcRealRTT, 2000);
    navigator?.connection?.addEventListener("change", function () {
      window.talkvioRealRTT = null;
      setTimeout(calcRealRTT, 5000);
    });
  }, []);

  useLayoutEffect(() => {
    setTimeout(() => {
      window.loadingFinish();
    }, 50);
  });

  if (getCookie("screenlog")) {
    logT("console", "lgs redirect logging to screen");
    window.screenLogs = true;
  }

  useEffect(() => {
    window.APP_ON("attachFile", (json) => {
      if (typeof json == "string") {
        logT("editor", "open editor with json", json.length);
        const jsonObj = JSON.parse(json.replace(/(\r\n|\n|\r)/gm, ""));
        if (jsonObj.file && jsonObj.mimeType) {
          if (jsonObj.mimeType.startsWith("image/")) {
            logT(
              "editor",
              "open editor with image",
              jsonObj.file.length,
              "mime",
              jsonObj.mimeType,
            );
            window.openEditor({
              blocks: [
                {
                  type: "image",
                  data: `data:${jsonObj.mimeType};base64,${jsonObj.file}`,
                },
              ],
            });
          } else if (jsonObj.mimeType.startsWith("video/")) {
            logT(
              "editor",
              "open editor with video",
              jsonObj.file.length,
              "mime",
              jsonObj.mimeType,
            );
            window.openEditor({
              blocks: [
                {
                  type: "video",
                  data: `data:${jsonObj.mimeType};base64,${jsonObj.file}`,
                },
              ],
            });
          } else if (jsonObj.blocks?.length > 0) {
            logT("editor", "open editor with json", jsonObj.blocks.length);
            window.openEditor({ blocks: jsonObj.blocks });
          } else {
            logTE("editor", "unknown file type, ignore");
          }
        } else {
          window.openEditor({ blocks: jsonObj.blocks });
        }
      }
    });
    if (
      typeof window.TalkvioAndroid != "undefined" &&
      window.TalkvioAndroid.onTalkvioReady
    ) {
      window.TalkvioAndroid.onTalkvioReady();
    }
    return () => {
      window.APP_OFF("attachFile");
    };
  }, []);

  useEffect(() => {
    const { proxy: lazyStorageReaded } = lazyStorageUse("readed");
    const { proxy: lazyStorageReadedTimestamp } =
      lazyStorageUse("readedTimestamp");
    if (!lazyStorageReadedTimestamp.timestamp) {
      lazyStorageReadedTimestamp.timestamp = Date.now();
      logT(
        "readed-record",
        "set initial timestamp",
        lazyStorageReadedTimestamp.timestamp,
      );
    } else {
      logT(
        "readed-record",
        "readed records:",
        Object.values(lazyStorageReaded).length,
      );
      logT(
        "readed-record",
        "timestamp",
        lazyStorageReadedTimestamp.timestamp,
        "expire",
        (lazyStorageReadedTimestamp.timestamp +
          7 * 24 * 60 * 60 * 1000 -
          Date.now()) /
          1000 +
          "s",
      );
      if (
        lazyStorageReadedTimestamp.timestamp + 7 * 24 * 60 * 60 * 1000 <
        Date.now()
      ) {
        lazyStorageReadedTimestamp.timestamp =
          Date.now() - 3 * 24 * 60 * 60 * 1000;
        logT(
          "readed-record",
          "update new timestamp",
          lazyStorageReadedTimestamp.timestamp,
        );
        for (const id in lazyStorageReaded) {
          if (
            lazyStorageReaded[id].timestamp <
            lazyStorageReadedTimestamp.timestamp
          ) {
            logT("readed-record", "delete old readed record", id);
            delete lazyStorageReaded[id];
          }
        }
      }
    }
  }, []);

  return (
    <div className="Talkvio">
      <Keys>
        <Threme>
          <Translations>
            <Login>
              <Header />
              <div className="pagecontent">
                <ScreenMessage />
                <ConfirmMessage />
                <LeftPanel />
                <Routes>
                  <Route path="/" element={<Main />} />
                  <Route path="/sections" element={<Forums />} />
                  <Route path="/activity" element={<Activity />} />
                  <Route path="/top" element={<Popular />} />
                  <Route path="/random" element={<Random />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/subscriptions" element={<Subscriptions />} />
                  <Route path="/bookmarks" element={<Bookmarks />} />
                  <Route path="/answers" element={<Answers />} />
                  <Route path="/mycomments" element={<MyComments />} />
                  <Route path="/liked" element={<Liked />} />
                  <Route path="/original" element={<Original />} />
                  <Route path="/delayedposts" element={<DelayedPosts />} />
                  <Route path="/mostcommented" element={<MostCommented />} />
                  <Route path="/forums/:forumid" element={<Threads />} />
                  <Route path="/section/:forumid" element={<Section />} />
                  <Route path="/post/:postid" element={<Posts />} />
                  <Route path="/threads/:threadid" element={<Posts />} />
                  <Route path="/user/:userid" element={<User />} />
                  <Route path="/tag/:tag" element={<Tag />} />
                  <Route
                    path="/restorepassword/:confirmid"
                    element={<RestorePassword />}
                  />
                  <Route
                    path="/confirmregistation/:confirmid"
                    element={<ConfirmPassword />}
                  />
                  <Route path="/vklogin" element={<VKLogin />} />
                  <Route path="/googlelogin" element={<GoogleAuthPage />} />
                  <Route path="/search" element={<SearchPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/removeaccount" element={<RemoveAccount />} />
                  <Route path="/blocks" element={<Blocks />} />

                  <Route
                    path="/adscontent"
                    element={<SpecialPage page="adscontent" />}
                  />
                  <Route
                    path="/contest"
                    element={<SpecialPage page="contest" />}
                  />
                  <Route
                    path="/policy"
                    element={<SpecialPage page="policy" />}
                  />
                  <Route
                    path="/refcon"
                    element={<SpecialPage page="refcontest" />}
                  />
                  <Route
                    path="/refwork"
                    element={<SpecialPage page="refwork" />}
                  />

                  <Route path="/:username" element={<User />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <RightPanel />
              </div>
              <Overlay />
              <Footer />
            </Login>
          </Translations>
        </Threme>
      </Keys>
      <div id="lgses">
        <div
          className="lgsCloseButton"
          onClick={() => {
            logT("console", "lgs off");
            window.screenLogs = false;
            setCookie("screenlog", false, 30);
            document.getElementById("lgses").style.display = "none";
          }}
        >
          X
        </div>
        <div className="lgsVersion">
          <div className="lgsClient">{process.env.REACT_APP_GIT_DESCRIBE}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
